
// LOCAL CUSTOM COMPONENTS
import MegaMenu1 from "../mega-menu/mega-menu-1.jsx";
import MegaMenu2 from "../mega-menu/mega-menu-2.jsx";
import CategoryListItem from "../category-list-item/index.js";
// NAVIGATION DATA

import { categoryMenus } from "@/data/navigations";
// STYLED COMPONENT
import { Wrapper } from "../category-list-item/styles.js";
import { StyledRoot } from "./styles.js";
import {Link} from "@inertiajs/react";
// PROPS TYPE

export default function CategoryList({
  open,
  position = "absolute",
  categories
}) {
  return <StyledRoot open={open} position={position}>
      {categories?.map(item => {
      const {
          slug,
          name,
      } = item;
          return (
              <Wrapper key={slug}>
                  <Link href={'/categories/'+slug}>
                      <div className="category-dropdown-link">
                          <span className="title">{name}</span>
                      </div>
                  </Link>
              </Wrapper>
          );
    })}
    </StyledRoot>;
}
