
// CUSTOM ICON COMPONENTS
import Google from "@/icons/Google";
import Twitter from "@/icons/Twitter";
import Youtube from "@/icons/Youtube";
import Facebook from "@/icons/Facebook";
import Instagram from "@/icons/Instagram";
import PlayStore from "@/icons/PlayStore";
import AppleStore from "@/icons/AppleStore";
export const ABOUT_LINKS = [{title: "Terms of Service",href: "/terms-of-service"}, {title: "Privacy Policy", href: "/privacy-policy"}];
export const CUSTOMER_CARE_LINKS = [{title: "Track Your Order", href: '/user/orders'}, {title: "Return & Refund", href: '/return-and-refund'}];
export const SOCIAL_ICON_LINKS = [{
  Icon: Facebook,
  url: "#"
}, {
  Icon: Twitter,
  url: "#"
}, {
  Icon: Youtube,
  url: "#"
}, {
  Icon: Google,
  url: "#"
}, {
  Icon: Instagram,
  url: "#"
}];
export const PLAY_APP_STORE_DATA = [{
  url: "#",
  icon: PlayStore,
  title: "Google Play",
  subtitle: "Get it on"
}, {
  url: "#",
  icon: AppleStore,
  title: "App Store",
  subtitle: "Download on the"
}];
